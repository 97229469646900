exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auditing-services-tsx": () => import("./../../../src/pages/auditingServices.tsx" /* webpackChunkName: "component---src-pages-auditing-services-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customized-sms-tsx": () => import("./../../../src/pages/customizedSms.tsx" /* webpackChunkName: "component---src-pages-customized-sms-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inquire-tsx": () => import("./../../../src/pages/inquire.tsx" /* webpackChunkName: "component---src-pages-inquire-tsx" */),
  "component---src-pages-internal-auditing-tsx": () => import("./../../../src/pages/internalAuditing.tsx" /* webpackChunkName: "component---src-pages-internal-auditing-tsx" */),
  "component---src-pages-manual-development-tsx": () => import("./../../../src/pages/manualDevelopment.tsx" /* webpackChunkName: "component---src-pages-manual-development-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-safety-assistance-tsx": () => import("./../../../src/pages/safetyAssistance.tsx" /* webpackChunkName: "component---src-pages-safety-assistance-tsx" */),
  "component---src-pages-safety-consulting-tsx": () => import("./../../../src/pages/safetyConsulting.tsx" /* webpackChunkName: "component---src-pages-safety-consulting-tsx" */),
  "component---src-pages-safety-training-tsx": () => import("./../../../src/pages/safetyTraining.tsx" /* webpackChunkName: "component---src-pages-safety-training-tsx" */),
  "component---src-pages-sms-tsx": () => import("./../../../src/pages/sms.tsx" /* webpackChunkName: "component---src-pages-sms-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-training-development-tsx": () => import("./../../../src/pages/trainingDevelopment.tsx" /* webpackChunkName: "component---src-pages-training-development-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

